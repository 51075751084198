import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue"
import store from "@/store"

class DataLayer{
    public static getOrgProfileData(): any{
        // ApiService.setHeader()
        return ApiService.get("organizationdata?organizationId="+store.state.organizationId)
    }
    public static getmanufacturersData(): any{
        return ApiService.get("manufacturersdata?consumerId="+store.state.organizationId)
    }
    public static getConsumerData(): any{
        return ApiService.get("consumersdata?manufacturerId="+store.state.organizationId)
    }
}

export default DataLayer;