<template>

  <div class="card">
    <div class="card-header pt-4">
      <!--begin::Card title-->
      <div class="card-title">
        <h2 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Manufacturer Details</span>
        <!-- <span class="text-muted mt-1 fw-bold fs-7"
          >More than 400 new products</span> -->
      </h2>
        <!--begin::Search-->
        <!-- <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="searchItems()"
            class="form-control form-control-solid w-250px ps-15"
            placeholder="Search Users"
          />
        </div> -->
        <!--end::Search-->
      </div>
      <!--begin::Card title-->
    </div>
        
    <div v-if ="flag" class="card-body pt-0">
      <!-- <h1>{{this.userdata}} </h1>
     <h1>{{ JSON.parse(JSON.stringify(this.userdata))}}</h1> -->
      <Datatable
        :table-data="JSON.parse(JSON.stringify(this.manufacturersData))"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :disablePagination="true"
        
      >
        <!-- <template v-slot:cell-checkbox="{ row: customer }">
          <div
            class="form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :value="customer.id"
              v-model="checkedCustomers"
            />
          </div>
        </template> -->
        
        <template v-slot:cell-icon="{ row: customer }">
          <div class="symbol symbol-45px me-2">
                      <span class="symbol-label fs-2x fw-bold text-warning bg-light-warning">{{customer.organizationName[0]}}</span>
            </div>
         
        </template>
        <template v-slot:cell-name="{ row: customer }">
          <span class="text-gray-600 text-hover-primary mb-1">
            
                      
            <span class="text-gray-600 fw-bolder text-hover-primary mb-1 fs-6">
            {{ customer.organizationName }}
            </span>
          </span>
        </template>
        <template v-slot:cell-fromdate="{ row: customer }">
          <span class="text-gray-600 text-hover-primary mb-1">
            {{ customer.consumerFromDateTime }}
          </span>
        </template>
        <template v-slot:cell-apiused="{ row: customer }">
          <span class="text-gray-600 text-hover-primary mb-1 badge badge-light-success fw-bolder">
            <span v-for="names in customer.name" :key="names">
                      {{names}}
                      <span v-if="names != customer.name[customer.name.length-1]">, </span>
                    </span>
          </span>
        </template>

        
        
      
        <!--new action button end-->


        
         
        <!-- <template v-slot:cell-actions="{ row: customer }">
          <button type="button"
            
            class="btn btn-sm btn-light btn-active-light-primary"
            data-kt-menu-flip="top-end"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-bs-target="#kt_modal_edit_customer"
            
            
            >Actions
            <span class="svg-icon svg-icon-5 m-0">
              <inline-svg src="media/icons/duotune/arrows/arr072.svg" />
            </span>
          </button> -->
          <!--begin::Menu-->
          <!-- <div
            class="
              menu
              menu-sub
              menu-sub-dropdown
              menu-column
              menu-rounded
              menu-gray-600
              menu-state-bg-light-primary
              fw-bold
              fs-7
              w-125px
              py-4
            "
            data-kt-menu="true"
          >

            <div
            class="menu-item px-3"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_edit_customer"
          >
           <a class="menu-link px-3">Edit</a>
          </div> -->

            <!--end::Menu item-->
            <!--begin::Menu item-->
            <!-- <div class="menu-item px-3">
              <a @click="deleteCustomer(customer.id)" class="menu-link px-3"
                >Delete</a
              >
            </div> -->
            <!--end::Menu item-->
          <!-- </div> -->
          <!--end::Menu-->
        <!-- </template> -->

      </Datatable>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Globals from "@/views/common/Globals.vue";
import DataLayer from "@/core/services/DataLayer"

export default defineComponent({
  name: "ManufacturersList",
  mixins: [Globals],
  components: {
    Datatable,
  },
  data(){
     return{
      manufacturersData: [],
      flag: false
     }
   },

   methods:{
     async getManufacturerData()
    {
      let response = await DataLayer.getmanufacturersData()
      this.manufacturersData = response.data.data
      this.flag = true
      for (const [key, value] of Object.entries(this.manufacturersData))
      {
        value.consumerFromDateTime = this.getDateInUtc(value.consumerFromDateTime)
      }
      
    },
   },
   mounted(){
       setCurrentPageBreadcrumbs("Manufacturers Details", ["Account"]);
        this.getManufacturerData() 
     },


  setup() {
    const checkedCustomers = ref([]);
    const tableHeader = ref([

      {
        name: "",
        key: "icon",
      },
     
      {
        name: "Name",
        key: "name",
      },
       {
        name: "From Date",
        key: "fromdate",
      },
      {
        name: "API Used",
        key: "apiused",
      },
    ]);

    onMounted(() => {
      // setCurrentPageTitle("User Managements")
      MenuComponent.reinitialization();
      // initCustomers.value.splice(0, customers.value.length, ...customers.value);
      
      // setCurrentPageBreadcrumbs("User Management", ["Settings", "Account"]);
    });

    return {
      tableHeader,
      checkedCustomers,
    };
  },
});
</script>


